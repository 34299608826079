import {useRouter} from 'next/router';
import {Row, Col, Button} from 'antd';
import {NextImg} from '@/utils/nextImg';

export default function NotFound() {
  const router = useRouter();

  return (
    <Row className='notFound' align='middle' justify='center'>
      <Col>
        <NextImg src='/img/404.svg' />
        <div className='message'>Looks like the page you’re trying to access does not exist.</div>
        <Button className='button' onClick={() => router.back()}>Go Back to Previous Page</Button>
      </Col>
    </Row>
  );
}
